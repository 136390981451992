import { Box } from "@chakra-ui/react";
import BannerArrowDown from "components/elements/BannerArrowDown";
import HeroSection from "./components/HeroSection";
import OrganizationsSection from "./components/OrganizationsSection";
import OutStandingFeaturesSection from "./components/OutstadingFeaturesSection";
import TimelineSection from "./components/TimelineSection";
import TopicSection from "./components/TopicSection";
import SponsorsSection from "./components/SponsorSection";
// import BenefitsSection from './components/BenefitsSection';

export interface HomeProps {}

export default function Home(props: HomeProps) {
	return (
		<Box w="100%" maxW="1100px" m="auto">
			<HeroSection />
			<BannerArrowDown />
			<TopicSection />
			<BannerArrowDown />
			<OutStandingFeaturesSection />
			{/* <BannerArrowDown /> */}
			{/* <BenefitsSection /> */}
			<BannerArrowDown />
			<TimelineSection />
			<BannerArrowDown />
			{/* <PrizeSection /> */}
			{/* <BannerArrowDown /> */}
			<SponsorsSection />
			<BannerArrowDown />
			<OrganizationsSection />
		</Box>
	);
}
