import { Box, Heading, Icon, Text } from "@chakra-ui/react";
import Title from "components/elements/Title";
import ElementEffectEntering from "components/shared/effect/ElementEffectEntering";
import { GrBottomCorner, GrTopCorner } from "react-icons/gr";

const GOALS: { img: string; title: string; desc: string }[] = [
  {
    img: "https://d33wubrfki0l68.cloudfront.net/46488b0c05421f6e8fe062aaed823329f30bce00/7ae3e/img/uploads/13.svg",
    title: "Hành động vì khí hậu",
    desc: "Hành động khẩn cấp để ứng phó với các tác động của biến đổi khí hậu.",
  },
  {
    img: "https://d33wubrfki0l68.cloudfront.net/5228e002c12365261fb0792e82de1106ee2b0aea/f397d/img/uploads/14.svg",
    title: "Môi trường nước",
    desc: "Sử dụng và bảo tồn nguồn tài nguyên nước bền vững.",
  },
  {
    img: "https://d33wubrfki0l68.cloudfront.net/1af7774812143c1c4eac2f6bba23760ef9c57855/9c46d/img/uploads/15.svg",
    title: "Môi trường đất liền",
    desc: "Bảo vệ, phục hồi hệ sinh thái trên đất liền để thúc đẩy phát triển bền vững. ",
  },
];

export interface TopicSectionProps {}

export default function TopicSection(props: TopicSectionProps) {
  return (
    <Box>
      <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" gap="1.25rem" w="100%">
        <Box display="flex" flexDirection="column" gap="1.25rem" alignItems="center">
          <Title
            title="Chủ đề cuộc thi"
            titleProps={{
              fontSize: { base: "1.5rem", md: "1.75rem" },
              textTransform: "uppercase",
              fontWeight: "medium",
            }}
          />
          <Heading
            as="h3"
            bg="linear-gradient(to right, #F7EFF1 0%,  #A4D1E9 100%)"
            bgClip="text"
            color="transparent"
            fontSize={{ base: "2rem", md: "3.25rem" }}
            fontWeight="black"
          >
            DIGITAL TOURISM
          </Heading>
          <ElementEffectEntering
            motionProps={{
              initial: { y: "5rem" },
              animate: { y: 0, transition: { duration: 1, delay: 0.1 } },
            }}
          >
            <Box
              bg="linear-gradient(to right, rgba(127, 231, 134, 0) 0.1%, rgba(114, 185, 231, 0.4) 40.34%, rgba(137, 150, 215, 0.4) 84.67%, rgba(103, 106, 180, 0) 98.09%)"
              borderRadius="0px 20px"
              p="0.25rem 3rem 0rem"
              position="relative"
              mixBlendMode="hard-light"
            >
              <Icon as={GrTopCorner} position="absolute" top="0" left="0" color="white" fontSize="1.5rem" />
              <Icon as={GrBottomCorner} position="absolute" bottom="0" right="0" color="white" fontSize="1.5rem" />
              <Box display="flex" flexDirection="column" alignItems="center" fontSize={{ base: "1rem", md: "1.25rem" }} fontWeight="semibold">
                <Text as="span" color="white">
                  Du lịch 4.0:
                </Text>
                <Text
                  as="span"
                  color="white"
                  sx={{
                    textWrap: "nowrap",
                  }}
                  whiteSpace="nowrap"
                >
                  Thông minh - Đổi mới - Sáng tạo
                </Text>
              </Box>
            </Box>
          </ElementEffectEntering>
        </Box>
      </Box>
    </Box>
  );
}
