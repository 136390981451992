/* eslint-disable @typescript-eslint/no-unused-vars */
import { isNil } from "lodash";
import { Gradient } from "types/common";

export const getRGBA = (color: string, alpha: number = 1) => {
	if (!color) return color;

	color = color.toLowerCase();

	//
	// hex #RRGGBB
	if (color.startsWith("#") && color.length === 7) {
		const [r, g, b] = hexRRGGBB_to_rgb(color);
		return `rgba(${r}, ${g}, ${b}, ${alpha})`;
	}

	//
	// hex #RRGGBBAA
	if (color.startsWith("#") && color.length === 9) {
		const [r, g, b] = hexRRGGBBAA_to_rgb(color);
		return `rgba(${r}, ${g}, ${b}, ${alpha})`;
	}

	//
	// hex #RGB
	if (color.startsWith("#") && color.length === 4) {
		const [r, g, b] = hexRGB_to_rgb(color);
		return `rgba(${r}, ${g}, ${b}, ${alpha})`;
	}

	//
	// if color is rgb
	const RGB_REGEX = /^rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)$/;
	const [_, r, g, b] = color.match(RGB_REGEX)?.map(Number) ?? [];
	if (!isNil(r) && !isNil(g) && !isNil(b)) {
		return `rgba(${r}, ${g}, ${b}, ${alpha})`;
	}

	//
	// if color is rgba
	const RGBA_REGEX =
		/^rgba\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3}),\s*(\d*\.?\d*)\)$/;
	const [__, r2, g2, b2, a] = color.match(RGBA_REGEX)?.map(Number) ?? [];
	if (!isNil(r2) && !isNil(g2) && !isNil(b2) && !isNil(a)) {
		return `rgba(${r2}, ${g2}, ${b2}, ${alpha})`;
	}

	return color;
};

const GRADIENT_DEFAULT: Gradient = {
	direction: 90,
	from: "#7FE786",
	fromPercent: 7.42,
	to: "#58A7FE",
	toPercent: 93.62,
};

export const getCSSLinearGradient = (gradient?: Gradient) => {
	const {
		direction = GRADIENT_DEFAULT.direction,
		from = GRADIENT_DEFAULT.from,
		fromPercent = GRADIENT_DEFAULT.fromPercent,
		to = GRADIENT_DEFAULT.to,
		toPercent = GRADIENT_DEFAULT.toPercent,
	} = gradient ?? {};

	return `linear-gradient(${direction}deg, ${from} ${fromPercent}%, ${to} ${toPercent}%)`;
};

// Helper functions
const hexRRGGBB_to_rgb = (hex: string) => {
	const r = parseInt(hex.slice(1, 3), 16);
	const g = parseInt(hex.slice(3, 5), 16);
	const b = parseInt(hex.slice(5, 7), 16);
	return [r, g, b];
};

const hexRRGGBBAA_to_rgb = (hex: string) => {
	const r = parseInt(hex.slice(1, 3), 16);
	const g = parseInt(hex.slice(3, 5), 16);
	const b = parseInt(hex.slice(5, 7), 16);
	return [r, g, b];
};

const hexRGB_to_rgb = (hex: string) => {
	const r = parseInt(hex.slice(1, 2), 16);
	const g = parseInt(hex.slice(2, 3), 16);
	const b = parseInt(hex.slice(3, 4), 16);
	return [r, g, b];
};
