export interface RuleSection {
  heading: string;
  content: string | RuleSubSection[];
}

export interface RuleSubSection {
  subHeading?: string;
  text: string | string[];
}

const rulesData: RuleSection[] = [
  {
    heading: ' Đối tượng tham gia',
    content: `Sinh viên (không giới hạn chuyên ngành) đến từ các trường Đại học trên địa bàn thành phố Đà Nẵng và khu vực miền Trung.`,
  },
  {
    heading: 'Hình thức đăng ký',
    content: [
      {
        text: [
          'Thí sinh đăng ký tham gia theo đội (tối đa 4 thành viên) hoặc cá nhân từ ngày 10/06/2024 đến hết ngày 10/07/2024.',
          '**BTC không hỗ trợ ghép đội cho thí sinh đăng ký tham gia cuộc thi với hình thức cá nhân.**',
        ],
      },
    ],
  },
  {
    heading: 'Phí cam kết',
    content: [
      {
        text: [
          '50.000 VNĐ/Thí sinh/Đội thi.',
          'Phí cam kết được BTC ấn định cho mức độ cam kết của thí sinh trong quá trình tham gia cuộc thi UniHack 2024 và sẽ hoàn trả 100% cho Leader của đội thi.',
          '**Phí cam kết sẽ không được hoàn trả trong trường hợp đội thi tự ý bỏ thi (tính từ sau khi đội thi đăng ký và thanh toán phí thành công) và không hoàn thành các điều kiện theo bảng cam kết của cuộc thi, các điều kiện liên quan đến việc tham dự các sự kiện xuyên suốt cuộc thi.**',
        ],
      },
    ],
  },
  {
    heading: 'Tiêu chí chấm thi',
    content: [
      {
        text: [
          'Ý tưởng',
          'Tính khả thi dự án',
          'Mức độ hoàn thiện',
          'Kỹ thuật',
          'Cách thức trình bày',
        ],
      },
    ],
  },
  {
    heading: 'Cơ cấu các vòng thi',
    content: [
      {
        subHeading: 'Vòng ý tưởng: 10/06/2024 - 10/07/2024',
        text: [
          'Thí sinh/Đội thi nộp tài liệu mô tả dự án theo mẫu do BTC cung cấp và một video ngắn (tối đa 3 phút) mô tả ý tưởng dự án.',
          'Mọi trạng thái về tiến độ của dự án đều được chấp nhận.',
          'BTC công bố yêu cầu phát triển lần một cho các thí sinh/đội thi tại ngày Khai mạc cuộc thi (14/07/2024).',
          'Dưới sự hỗ trợ của đội tư vấn, các thí sinh/đội thi thực hiện phát triển dự án lần một.',
          'Kết thúc vòng ý tưởng, Top 25 thí sinh/đội thi xuất sắc nhất sẽ tiến vào vòng loại ý',
        ],
      },
      {
        subHeading: 'Vòng loại ý tưởng',
        text: [
          'Thời gian: 21/07/2024',
          'Hình thức dự thi: Offline',
          'Thí sinh/Đội thi chuẩn bị và trình bày dự án đã được phát triển lần một trước đội ngũ Mentor và Ban tổ chức.',
          'Kết thúc vòng loại ý tưởng, Top 10 thí sinh/đội thi xuất sắc nhất sẽ tiến vào vòng lập trình.',
        ],
      },
      {
        subHeading: 'Vòng lập trình',
        text: [
          'Thời gian: 27/07/2024 - 28/07/2024',
          'Hình thức dự thi: Offline, lập trình 24h liên tục',
          'BTC công bố yêu cầu phát triển lần hai cho các thí sinh/đội thi.',
          'Dưới sự hỗ trợ của các Mentor, các thí sinh/đội thi thực hiện phát triển dự án lần hai trong vòng 24h.',
          'Kết thúc vòng loại lập trình, Top 5 thí sinh/đội thi xuất sắc nhất sẽ tiến vào vòng chung kết.',
        ],
      },
      {
        subHeading: 'Vòng chung kết',
        text: [
          'Thời gian: 28/07/2024',
          'Hình thức dự thi: Offline',
          'Thí sinh/Đội thi chuẩn bị và trình bày dự án đã được phát triển lần hai trước đội ngũ Ban giám khảo.',
        ],
      },
    ],
  },
];

export default rulesData;
