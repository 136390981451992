import { Box, SystemProps } from "@chakra-ui/react";
import { MotionProps, motion, useAnimation, useInView } from "framer-motion";
import { useEffect, useRef } from "react";

export interface ElementEffectEnteringProps {
  children?: React.ReactNode;
  motionProps: MotionProps;
  containerProps?: SystemProps;
  elementProps?: SystemProps;
}

export default function ElementEffectEntering({ children, motionProps, containerProps, elementProps }: ElementEffectEnteringProps) {
  const ref = useRef<HTMLDivElement | null>(null);
  const isInView = useInView(ref, { once: true });
  const controls = useAnimation();
  const hasAnimated = useRef(false);

  useEffect(() => {
    if (isInView && !hasAnimated.current) {
      controls.start({
        opacity: 1,
        ...(motionProps.animate as any),
      });
      hasAnimated.current = true;
    }
  }, [isInView, controls, motionProps.animate]);
  return (
    <Box ref={ref} {...containerProps}>
      <Box
        as={motion.div}
        {...(motionProps as any)}
        initial={motionProps.variants ? motionProps.initial : { opacity: 0, ...(typeof motionProps.initial === "object" ? motionProps.initial : {}) }}
        animate={controls}
        {...elementProps}
      >
        {children}
      </Box>
    </Box>
  );
}
