import { Box } from "@chakra-ui/react";
import React from "react";
import Header from "components/layouts/components/Header";
import Footer from "components/layouts/components/Footer";
import ScrollToAnchor from "components/shared/ScrollToAnchor";
import ScrollToTopButton from "components/shared/ScrollToTopButton";
import ArrowDown from "components/elements/ArrowDown";
import { bg_image, dot } from "assets/images";

interface Props {
  children: React.ReactNode;
}

const Layout = ({ children }: Props) => {
  return (
    <Box>
      <ScrollToAnchor />
      <Header />
      <Box py="5rem" px="2rem" position="relative">
        {children}
        <Box
          position="absolute"
          bgImage={bg_image}
          bgRepeat="no-repeat"
          bgSize="cover"
          inset="0"
          opacity="0.6"
          mixBlendMode="overlay"
          zIndex="-1"
        ></Box>
        <Box position="absolute" bgImage={dot} inset="0" zIndex="-1"></Box>
      </Box>
      <Footer />
      <ArrowDown />
      <ScrollToTopButton />
    </Box>
  );
};

export default Layout;
