import { HeaderNav } from 'types/common';

export const headerNavDummy: HeaderNav[] = [
  {
    title: 'Về UniHack',
    href: '/',
    children: [
      {
        title: 'Proposal',
        href: '/',
        children: [
          {
            title: 'Proposal VIE',
            href: 'https://drive.google.com/file/d/1mPpfZGlTkHeOcRVmIdvEBL3Dg6k6alez/view',
          },
          {
            title: 'Proposal ENG',
            href: 'https://drive.google.com/file/d/14Cun7xR03dEkJitrG4bN8aRbb-9lFBqs/view',
          },
        ],
      },
      {
        title: 'Sổ tay thí sinh',
        href: 'https://url.gdsc.dev/UniHack2024_ContestantHandbook',
      },
      // {
      //   title: 'Mentor Desk',
      //   href: '/',
      // },
    ],
  },
  {
    title: 'Thể lệ cuộc thi',
    href: '/rules',
  },
  {
    title: 'Lịch trình',
    href: '/#timeline',
  },
  // {
  //   title: 'Đối tác',
  //   href: '/#sponsors',
  // },
  {
    title: 'Các mùa trước',
    href: '/',
    children: [
      {
        title: 'UniHack 2022',
        href: 'https://youtu.be/9WMYM7sUMAU?si=kLDWtIA4LXNxltqK',
      },
      {
        title: 'UniHack 2023',
        href: 'https://youtu.be/FiVHvYIWhd8?si=ZBsPCSpZWwhEkcXF',
      },
    ],
  },
];
