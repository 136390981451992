import { Box, BoxProps, Heading, Image, Text } from "@chakra-ui/react";
import { arrow_time_line, line, location } from "assets/images";

export interface TimelineChildProps {
  date: string;
  title: string;
  desc?: string;
  reverse?: boolean;
  showArrow?: boolean;
  lineProps?: BoxProps;
}

export default function TimelineChild({ date, title, desc, reverse, showArrow = true, lineProps }: TimelineChildProps) {
  return (
    <Box display="flex" justifyContent="flex-start" position="relative" w="100%">
      <Box position="absolute" top="50%" left="20%" transform={`translateY(-50%) ${reverse ? "rotate(180deg)" : ""}`} w="100%" {...lineProps}>
        <Image src={line} w="94%" h="4px" />
        {showArrow && <Image src={arrow_time_line} position="absolute" top="50%" left="48%" transform="translate(-50%, -50%) rotate(180deg)" />}
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center" gap="1rem">
        <Heading
          as="h3"
          fontSize="2.25rem"
          fontWeight="bold"
          bg="linear-gradient(to bottom, #0BB5E3 0%, #2AF698 100%)"
          bgClip="text"
          color="transparent"
        >
          {date}
        </Heading>
        <Image src={location} w="28px" h="48px" />
        <Box display="flex" flexDirection="column" alignItems="center">
          <Text as="p" fontWeight="semibold" textAlign="center">
            {title}
          </Text>
          {desc && (
            <Text as="p" fontWeight="semibold" textAlign="center">
              {desc}
            </Text>
          )}
        </Box>
      </Box>
    </Box>
  );
}
