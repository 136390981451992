import {
	Box,
	Button,
	Heading,
	Icon,
	Image,
	List,
	ListIcon,
	ListItem,
	Stack,
} from '@chakra-ui/react';
import { gdg_cloud_icon, gdsc_dut_icon, small_logo } from 'assets/images';
import ElementEffectEntering from 'components/shared/effect/ElementEffectEntering';
import {
	FaFacebook,
	FaGlobe,
	FaInstagram,
	FaLinkedin,
	FaPhone,
} from 'react-icons/fa';
import { IoMdMail } from 'react-icons/io';
import { Link } from 'react-router-dom';
import { handleScrollToTop } from 'utils/common';

export interface FooterProps {}

export default function Footer(props: FooterProps) {
	return (
		<Box
			as='footer'
			w='full'
			bg='#1e1e1e'
			transition='all 0.3s ease-in-out'
			position='relative'
			p={{ base: '2rem', lg: '3rem' }}
			overflow='hidden'
		>
			<Stack
				flexDirection={{ base: 'column', lg: 'row' }}
				alignItems='flex-start'
				justifyContent='space-between'
				w='100%'
				maxW='1100px'
				m='auto'
				gap='2rem'
			>
				<ElementEffectEntering
					motionProps={{
						initial: { y: '100%' },
						animate: { y: 0, transition: { duration: 0.5 } },
					}}
					containerProps={{
						mt: '0 !important',
					}}
				>
					<Box display='flex' flexDirection='column' gap='1.5rem'>
						<Heading
							as='h4'
							pb='0.25rem'
							borderBottom='2px solid'
							borderColor='main'
							w='max-content'
							fontSize='1.125rem'
						>
							Theo dõi chúng tôi tại
						</Heading>
						<List display='flex' alignItems='center' gap='1rem'>
							<a
								href='https://www.facebook.com/gdsc.dut'
								target='_blank'
								rel='noopener noreferrer'
							>
								<ListIcon
									color='white'
									as={FaFacebook}
									w='1.75rem'
									h='1.75rem'
								/>
							</a>
							<a
								href='https://www.instagram.com/gdsc_dut'
								target='_blank'
								rel='noopener noreferrer'
							>
								<ListIcon
									color='white'
									as={FaInstagram}
									w='1.75rem'
									h='1.75rem'
								/>
							</a>
							<a
								href='http://linkedin.com/company/gdscdut'
								target='_blank'
								rel='noopener noreferrer'
							>
								<ListIcon
									color='white'
									as={FaLinkedin}
									w='1.75rem'
									h='1.75rem'
								/>
							</a>
							<a
								href='https://dut.gdsc.dev'
								target='_blank'
								rel='noopener noreferrer'
							>
								<ListIcon
									color='white'
									as={FaGlobe}
									w='1.75rem'
									h='1.75rem'
								/>
							</a>
						</List>
					</Box>
				</ElementEffectEntering>

				<ElementEffectEntering
					motionProps={{
						initial: { y: '100%' },
						animate: { y: 0, transition: { duration: 0.75 } },
					}}
					containerProps={{
						mt: '0 !important',
					}}
				>
					<Box display='flex' flexDirection='column' gap='1.5rem'>
						<Heading
							as='h4'
							pb='0.25rem'
							borderBottom='2px solid'
							borderColor='main'
							w='max-content'
							fontSize='1.125rem'
						>
							Đơn vị tổ chức
						</Heading>
						<List display='flex' flexDirection='column' gap='1rem'>
							<ListItem>
								<Image
									src={gdsc_dut_icon}
									alt='GDSC DUT'
									width={300}
								/>
							</ListItem>
							<ListItem>
								<Image
									src={gdg_cloud_icon}
									alt='GDG CLOUD'
									w={180}
								/>
							</ListItem>
						</List>
					</Box>
				</ElementEffectEntering>

				<ElementEffectEntering
					motionProps={{
						initial: { y: '100%' },
						animate: { y: 0, transition: { duration: 0.75 } },
					}}
					containerProps={{
						mt: '0 !important',
					}}
				>
					<Box display='flex' flexDirection='column' gap='1.25rem'>
						<Heading
							as='h4'
							pb='0.25rem'
							borderBottom='2px solid'
							borderColor='main'
							w='max-content'
							fontSize='1.125rem'
						>
							Liên hệ
						</Heading>
						<List display='flex' flexDirection='column' gap='1rem'>
							<ListItem
								display='flex'
								alignItems='center'
								gap='1rem'
							>
								<Icon
									color='white'
									as={FaPhone}
									transform='rotate(95deg)'
									w='1.25rem'
									h='1.25rem'
								/>
								<Heading
									as='p'
									fontSize='1rem'
									fontWeight='medium'
								>
									Thanh Hương (Liên hệ chi tiết sự kiện): 0969
									273 748
								</Heading>
							</ListItem>
							<ListItem
								display='flex'
								alignItems='center'
								gap='1rem'
							>
								<Icon
									color='white'
									as={FaPhone}
									transform='rotate(95deg)'
									w='1.25rem'
									h='1.25rem'
								/>
								<Heading
									as='p'
									fontSize='1rem'
									fontWeight='medium'
								>
									Trần Bảo Ngọc (Liên hệ tài trợ - báo chí):
									0708 625 469
								</Heading>
							</ListItem>

							<ListItem
								display='flex'
								alignItems='center'
								gap='1rem'
							>
								<Icon
									color='white'
									as={FaPhone}
									transform='rotate(95deg)'
									w='1.25rem'
									h='1.25rem'
								/>
								<Heading
									as='p'
									fontSize='1rem'
									fontWeight='medium'
								>
									Sử Minh Thành (Liên hệ chuyên môn): 0368 682
									992
								</Heading>
							</ListItem>
							<ListItem
								display='flex'
								alignItems='center'
								gap='1rem'
							>
								<Icon
									color='white'
									as={IoMdMail}
									w='1.25rem'
									h='1.25rem'
								/>
								<Heading
									as='p'
									fontSize='1rem'
									fontWeight='medium'
								>
									partnership.gdscdut@gmail.com
								</Heading>
							</ListItem>
						</List>
					</Box>
				</ElementEffectEntering>
			</Stack>
			<Button
				onClick={handleScrollToTop}
				color='black'
				position='absolute'
				bottom='1rem'
				right='1rem'
				display={{ base: 'none', sm: 'block' }}
			>
				<ElementEffectEntering
					motionProps={{
						animate: { opacity: 1, transition: { duration: 0.75 } },
					}}
				>
					<Link to='/'>
						<Image src={small_logo} w={200} />
					</Link>
				</ElementEffectEntering>
			</Button>
		</Box>
	);
}
