import { ChevronDownIcon, HamburgerIcon } from '@chakra-ui/icons';
import {
	Box,
	Button,
	IconButton,
	Image,
	List,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	PlacementWithLogical,
	Text,
} from '@chakra-ui/react';
import { small_logo } from 'assets/images';
import ElementEffect from 'components/shared/effect/ElementEffect';
import { headerNavDummy } from 'data/common.dummy';
import useBreakpoint from 'hooks/useBreakpoint';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { HeaderNav } from 'types/common';
import { handleScrollToTop } from 'utils/common';

export interface HeaderProps {}

export default function Header(props: HeaderProps) {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const breakpoint = useBreakpoint();
	const handleToggleMenu = () => {
		setIsOpen(prev => !prev);
	};
	
	const renderMenuItem = (title: string) => {
		return (
			<Box
				position='relative'
				display='flex'
				alignItems='center'
				gap='0.5rem'
			>
				<Text
					as='span'
					fontSize='1rem'
					transition='all 0.35s ease-in-out'
					_groupHover={{
						color: 'main',
						transform: 'translateX(0.25rem)',
					}}
				>
					{title}
				</Text>
				<Box
					position='absolute'
					left='calc(50% + 0.25rem)'
					transform='translateX(-50%)'
					bottom='-0.5rem'
					width='0px'
					h='2px'
					bg='main'
					transition='all 0.2s ease-in-out'
					_groupHover={{
						width: '100%',
					}}
				></Box>
			</Box>
		);
	};

	const renderMenu = (
		header: HeaderNav,
		index: number,
		placement?: PlacementWithLogical
	) => {
		return header.children ? (
			<Menu
				autoSelect={false}
				key={index}
				offset={[0, 0]}
				placement={
					breakpoint === 'base' ? 'auto' : 
					header.title === 'Proposal'
						? 'left-start'
						: placement || 'auto'
				}
			>
				<MenuButton
					as={Button}
					rightIcon={<ChevronDownIcon />}
					color='black'
					width='100%'
					p='6px 12px'
					justifyContent='space-between'
					role='group'
				>
					{header.href.startsWith('http') ? (
						<a
							href={header.href}
							target='_blank'
							rel='noopener noreferrer'
						>
							{renderMenuItem(header.title)}
						</a>
					) : (
						<Link to={header.href}>
							{renderMenuItem(header.title)}
						</Link>
					)}
				</MenuButton>

				<MenuList>
					{header.children.map((child, childIndex) =>
						renderMenu(child, childIndex)
					)}
				</MenuList>
			</Menu>
		) : (
			<Box role='group'>
				<MenuItem
					onClick={handleToggleMenu}
					key={index}
					as={Button}
					justifyContent='flex-start'
					_active={{
						bg: 'transparent',
					}}
					_focus={{
						bg: 'transparent',
					}}
				>
					{header.href.startsWith('http') ? (
						<a
							href={header.href}
							target='_blank'
							rel='noopener noreferrer'
						>
							{renderMenuItem(header.title)}
						</a>
					) : (
						<Link to={header.href}>
							{renderMenuItem(header.title)}
						</Link>
					)}
				</MenuItem>
			</Box>
		);
	};

	return (
		<Box
			as='header'
			position='fixed'
			top='0'
			w='full'
			zIndex='30'
			bg='#1E1E1E'
			px={{ base: '2rem', xl: '3rem' }}
			transition='all 0.3s ease-in-out'
		>
			<Box
				display='flex'
				alignItems='center'
				justifyContent='space-between'
				h='5rem'
				w='100%'
				maxW='1100px'
				m='auto'
			>
				<ElementEffect
					motionProps={{
						initial: { opacity: 0 },
						animate: { opacity: 1 },
					}}
					elementType={'h2'}
				>
					<Button p='0' onClick={handleScrollToTop}>
						<Link to='/'>
							<Image src={small_logo} w={200} />
						</Link>
					</Button>
				</ElementEffect>
				<List
					display={{ base: 'none', lg: 'flex' }}
					alignItems='center'
					gap={'1rem'}
					bg='#1E1E1E'
				>
					<Menu autoSelect={false} isOpen={isOpen}>
						{headerNavDummy.map((nav, index) => (
							<ElementEffect
								key={index}
								motionProps={{
									initial: { y: '-100%' },
									animate: {
										y: 0,
										transition: { delay: index * 0.1 },
									},
								}}
								elementType={'li'}
							>
								{renderMenu(nav, index)}
							</ElementEffect>
						))}
					</Menu>
				</List>

				<ElementEffect
					motionProps={{
						initial: { opacity: 0 },
						animate: { opacity: 1 },
					}}
				>
					<Box
						display={{ base: 'block', lg: 'none' }}
						className='mobile_menu'
					>
						<Menu autoSelect={false} isOpen={isOpen}>
							<MenuButton
								onClick={handleToggleMenu}
								as={IconButton}
								aria-label='Options'
								icon={<HamburgerIcon />}
								variant='outline'
							/>
							<MenuList transition='all 0.1s' zIndex={999}>
								{headerNavDummy.map((nav, index) =>
									renderMenu(nav, index)
								)}
							</MenuList>
						</Menu>
					</Box>
				</ElementEffect>
			</Box>
		</Box>
	);
}
