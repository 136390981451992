import { Box, Button, Flex, Image, Link, VStack } from "@chakra-ui/react"
import { logo, map } from "assets/images"
// import { Link } from 'react-router-dom';

export interface LogoProps {}

export default function Logo(props: LogoProps) {
    return (
        <Box>
            <Box
                position="relative"
                display={{ base: "none", md: "flex" }}
                flexDirection="column"
                alignItems="center"
                gap="3rem"
            >
                <Image
                    src={logo}
                    width={{ base: 562, lg: "auto" }}
                    h={{ base: 220, lg: "auto" }}
                />
                <Image src={map} w={350} alt="map" />
                {/* <Link href="https://forms.gle/j6QD9bgLzZP2xEKw5" isExternal>
                    <Button
                        background="transparent"
                        width="15.5rem"
                        h="4rem"
                        borderRadius={90}
                        outlineColor={"#29C0CE"}
                        _hover={{
                            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                            background: "white",
                            color: "#104D5A",
                            transition: "all 0.35s ease-in-out",
                        }}
                        fontSize={"1.5rem"}
                        fontWeight={"bold"}
                    >
                        ĐĂNG KÝ NGAY
                    </Button>
                </Link> */}
            </Box>
            {/* <VStack gap="3rem" display={{ base: "flex", md: "none" }}>
                <Image src={logo} w={300} />
                <Link href="https://forms.gle/j6QD9bgLzZP2xEKw5" isExternal>
                    <Button
                        background="transparent"
                        width="15.5rem"
                        h="4rem"
                        borderRadius={90}
                        outlineColor={"#29C0CE"}
                        _hover={{
                            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                            background: "white",
                            color: "#104D5A",
                            transition: "all 0.35s ease-in-out",
                        }}
                        fontSize={"1.5rem"}
                        fontWeight={"bold"}
                    >
                        ĐĂNG KÝ NGAY
                    </Button>
                </Link>
            </VStack> */}
        </Box>
    )
}
