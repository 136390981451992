import { Heading, HeadingProps } from "@chakra-ui/react";
import ElementEffectEntering from "components/shared/effect/ElementEffectEntering";

export interface TitleProps {
  title: string;
  titleProps?: HeadingProps;
  ElementType?: React.ElementType;
}

export default function Title({ title, ElementType = "h2", titleProps }: TitleProps) {
  return (
    <ElementEffectEntering
      motionProps={{
        initial: { opacity: 0, y: 50 },
        animate: { opacity: 1, y: 0, transition: { duration: 0.75 } },
      }}
    >
      <Heading fontWeight="bold" fontSize={{ base: "1.5rem", lg: "3rem" }} as={ElementType} {...titleProps}>
        {title}
      </Heading>
    </ElementEffectEntering>
  );
}
