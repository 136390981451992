import { Box, Grid, GridItem, Image, Text } from "@chakra-ui/react";
import { gdgcloud_logo_full, gdsc_logo_full } from "assets/images";
import Title from "components/elements/Title";
import ElementEffectEntering from "components/shared/effect/ElementEffectEntering";

const ORGANIZATIONS = [
  {
    title: "ĐƠN VỊ TỔ CHỨC",
    content: [
      {
        img: gdsc_logo_full,
        title: "Google Developer Student Club - Danang University of Science and Technology (GDSC - DUT)",
        desc: [
          "Google Developer Student Club - Danang University of Science and Technology là một chapter thuộc GDSC Vietnam. Đây là môi trường hữu ích giúp sinh viên Trường Đại học Bách Khoa Đà Nẵng nói riêng và sinh viên các trường Đại học Đà Nẵng nói chung dễ dàng tiếp cận với những thông tin, kiến ​​thức bổ ích trong lĩnh vực công nghệ. Bên cạnh đó, cộng đồng tạo ra nhiều sự kiện và dự án để các bạn sinh viên được kết nối và học hỏi thêm.",
        ],
      },
      {
        img: gdgcloud_logo_full,
        title: "GDG Cloud Danang",
        desc: [
          "GDG Cloud Danang là cộng đồng được thành lập với mục đích tạo ra nơi giao lưu nhằm chia sẻ kiến thức, kinh nghiệm và những thông tin mới nhất về công nghệ cho những người dùng Google Cloud Platform tại Đà Nẵng.",
          "Với lượt tiếp cận lên đến 13.000 người bao gồm những lập trình viên chuyên nghiệp và những người quan tâm, yêu thích và muốn tiếp cận sâu hơn về công nghệ Google. Đồng hành cùng GDG MienTrung, GDG Cloud Danang thường xuyên tổ chức nhiều buổi Workshop và sự kiện thú vị như Google I/O Extended và GDG DevFest Mien Trung…",
        ],
      },
    ],
  },
  {
    title: "ĐƠN VỊ ĐỒNG HÀNH",
    content: [
      {
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQan5u5HKbh9WDXqMSaRo1Nu49ZqyiASaoYJw&s",
        title: "S-Group",
        desc: [
          "S-Group là tổ chức hỗ trợ và đào tạo nền tảng chuyên môn cũng như phát triển các kinh nghiệm thực tiễn cho các bạn sinh viên. Hiện nay, S-Group đang đào tạo 3 chuyên môn chính: Lập trình, Marketing Online và Thiết kế, được hướng dẫn và đào tạo do chính các thành viên có kinh nghiệm làm việc trong các lĩnh vực trực tiếp đào tạo và chia sẻ.",
        ],
      },
    ],
  },
];

export interface OrganizationsSectionProps {}

export default function OrganizationsSection(props: OrganizationsSectionProps) {
  return (
    <Box display="flex" flexDirection="column" gap="3rem">
      {ORGANIZATIONS.map((ORGANIZATION, index) => (
        <Box key={index} display="flex" justifyContent="center" alignItems="center" flexDirection="column" gap="1.25rem" w="100%">
          <Box display="flex" justifyContent="flex-start" w="100%">
            <Title title={ORGANIZATION.title} />
          </Box>
          <Grid w="100%" gap="1rem">
            {ORGANIZATION.content.map((organization, idx) => (
              <GridItem key={idx} width="100%" display="flex" mt="2rem">
                <Box
                  display="flex"
                  flexDirection={{
                    base: "column",
                    lg: "row",
                  }}
                  alignItems="center"
                  gap="2rem"
                >
                  <Box position="relative" flexShrink="0" width={{ base: "100%", sm: "250px" }}>
                    <Image
                      src={organization.img}
                      objectFit="cover"
                      width={{ base: "100%", sm: "250px" }}
                      height={{ base: "100%", sm: "250px" }}
                      alt={organization.title}
                      borderRadius="1rem"
                      loading="lazy"
                      aria-label={organization.title}
                    />
                    <ElementEffectEntering
                      motionProps={{
                        initial: {
                          scaleX: 1,
                          opacity: 1,
                        },
                        animate: {
                          scaleX: 0,
                          opacity: 1,
                          transition: { duration: 1 },
                        },
                      }}
                      containerProps={{
                        position: "absolute",
                        inset: 0,
                        borderRadius: "1rem",
                        overflow: "hidden",
                      }}
                      elementProps={{
                        position: "absolute",
                        inset: 0,
                        borderRadius: "1rem",
                        bg: "white",
                        transformOrigin: "right",
                      }}
                    />
                  </Box>

                  <Box display="flex" flexDirection="column" gap="0.5rem">
                    <ElementEffectEntering
                      motionProps={{
                        initial: { opacity: 0, y: 50 },
                        animate: {
                          opacity: 1,
                          y: 0,
                          transition: {
                            duration: 0.75,
                          },
                        },
                      }}
                    >
                      <Title
                        title={organization.title}
                        ElementType="h3"
                        titleProps={{
                          fontSize: "1.35rem",
                          fontWeight: "bold",
                        }}
                      />
                    </ElementEffectEntering>
                    <ElementEffectEntering
                      motionProps={{
                        initial: { opacity: 0, y: 50 },
                        animate: {
                          opacity: 1,
                          y: 0,
                          transition: {
                            duration: 0.75,
                            delay: 0.5,
                          },
                        },
                      }}
                    >
                      <Text as="p" fontSize="1.15rem">
                        {organization.desc.map((paragraph, idx) => (
                          <Text key={idx} textAlign="justify">
                            {paragraph}
                            <Box as="br" />
                          </Text>
                        ))}
                      </Text>
                    </ElementEffectEntering>
                  </Box>
                </Box>
              </GridItem>
            ))}
          </Grid>
        </Box>
      ))}
    </Box>
  );
}