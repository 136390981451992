import { Box, Grid, GridItem, Text } from "@chakra-ui/react";
import Title from "components/elements/Title";
import ElementEffectEntering from "components/shared/effect/ElementEffectEntering";
import React from "react";

const HIGHLIGHTS = [
  { title: "200.000+", desc: "Lượt tiếp cận" },
  { title: "1.000+", desc: "Người tham gia" },
  { title: "40+", desc: "Đối tác & Nhà tài trợ" },
  { title: "15+", desc: "Đơn vị báo đài đưa tin" },
];

interface OutStandingFeaturesSectionProps {}

const OutStandingFeaturesSection: React.FC<OutStandingFeaturesSectionProps> = () => {
  return (
    <Box as="fieldset" borderRadius="20px" borderWidth="4px" borderStyle="solid" borderColor="#29C0CE" textAlign="center">
      <Box as="legend" margin="0 auto" padding={{ base: "0 2rem", lg: "0 4rem" }}>
        <ElementEffectEntering
          motionProps={{
            initial: { opacity: 0, y: 50 },
            animate: {
              opacity: 1,
              y: 0,
              transition: { duration: 0.75, delay: 0.5 },
            },
          }}
        >
          <Title
            title="NHỮNG CON SỐ ẤN TƯỢNG QUA"
            titleProps={{
              textShadow: "0px 0px 20px rgb(150 210 255)",
            }}
          />
          <Title
            title="2 MÙA UNIHACK"
            titleProps={{
              textShadow: "0px 0px 20px rgb(150 210 255)",
            }}
          />
        </ElementEffectEntering>
      </Box>

      <Grid
        templateColumns={{
          base: "repeat(1, 1fr)",
          md: "repeat(2, 1fr)",
        }}
        w="100%"
        mt="2rem"
        gap="1rem"
      >
        {HIGHLIGHTS.map((hightlight, index) => (
          <GridItem key={index} minHeight="160px" width="100%" display="flex" justifyContent="center">
            <Box display="flex" flexDirection="column" gap="1.5rem" alignItems="center">
              <Box display="flex" flexDirection="column" alignItems="center" gap="0.5rem">
                <ElementEffectEntering
                  motionProps={{
                    initial: { opacity: 0, y: 50 },
                    animate: {
                      opacity: 1,
                      y: 0,
                      transition: { duration: 0.75 },
                    },
                  }}
                >
                  <Title
                    title={hightlight.title}
                    ElementType="h1"
                    titleProps={{
                      fontSize: "2.5rem",
                      fontWeight: "bold",
                      background: "-webkit-linear-gradient(white, #44cdda)",
                      backgroundClip: "text",
                      textShadow: "0px 0px 20px #44cdda",
                    }}
                  />
                </ElementEffectEntering>
                <Box
                  style={{
                    width: "100%",
                    height: "4px",
                    background: "linear-gradient(to right, #44cdda 20%, white 80%)",
                  }}
                ></Box>
                <ElementEffectEntering
                  motionProps={{
                    initial: { opacity: 0, y: 50 },
                    animate: {
                      opacity: 1,
                      y: 0,
                      transition: {
                        duration: 0.75,
                        delay: 0.5,
                      },
                    },
                  }}
                >
                  <Text as="p" textAlign="center" fontSize="1.15rem" fontWeight="bold" maxW="350px">
                    {hightlight.desc.toUpperCase()}
                  </Text>
                </ElementEffectEntering>
              </Box>
            </Box>
          </GridItem>
        ))}
      </Grid>
    </Box>
  );
};

export default OutStandingFeaturesSection;
