import { Box, Heading, Text } from "@chakra-ui/react";
import CountDown from "components/elements/CountDown";
import Logo from "components/shared/Logo";
import ElementEffectEntering from "components/shared/effect/ElementEffectEntering";

export interface HeroSectionProps {}

export default function HeroSection(props: HeroSectionProps) {
  return (
    <Box id="#">
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap="2rem" mt={{ base: "5rem", lg: "0" }}>
        <ElementEffectEntering
          motionProps={{
            initial: { scale: 0 },
            animate: { scale: 1, transition: { duration: 1 } },
          }}
          containerProps={{
            minHeight: { base: "auto", lg: "calc(100vh - 5rem)" },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            display="flex"
            flexDirection={{ base: "column", lg: "row" }}
            alignItems="center"
            justifyItems="start"
            gap={{ base: "5rem", lg: "2rem", xl: '5rem' }}
          >
            <Logo />
            <ElementEffectEntering
              motionProps={{
                initial: { y: "5rem" },
                animate: { y: 0, transition: { duration: 1 } },
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                gap={{ base: "1rem", lg: "2rem" }}
                alignItems="center"
                justifyItems="center"
                marginBottom="1rem"
              >
                <Heading
                  as="h4"
                  fontSize={{ base: "1.75rem", md: "2.35rem", xl: "2.5rem" }}
                  textAlign='center'
                  sx={{
                    textWrap: { base: "wrap", lg: "nowrap" },
                  }}
                  whiteSpace={{ base: "normal", lg: "nowrap" }}
                >
                  THỜI GIAN ĐĂNG KÝ ĐÃ KẾT THÚC
                </Heading>
                {/* <CountDown /> */}
              </Box>
            </ElementEffectEntering>
          </Box>
        </ElementEffectEntering>

				<ElementEffectEntering
					motionProps={{
						initial: { y: "15rem" },
						animate: { y: 0, transition: { duration: 1 } },
					}}
				>
					<Heading
						as="p"
						textAlign="justify"
						fontSize={{ base: "1rem", md: "1.25rem" }}
						fontWeight="semibold"
						lineHeight="3rem"
					>
						<Text as="span" fontWeight="bold" color="main">
							UniHack
						</Text>{" "}
						là cuộc thi lập trình dành cho các bạn sinh viên đam mê công nghệ và
						lập trình tại miền Trung chung tay vào việc xây dựng các giải pháp
						nhằm giải quyết các vấn đề xã hội. Cuộc thi được tổ chức với mục
						đích định hướng phát triển nghề nghiệp cũng như tạo cơ hội cọ xát
						với thực tiễn, đồng thời kết nối và khởi tạo cộng đồng các bạn trẻ
						đam mê công nghệ.
					</Heading>
				</ElementEffectEntering>

				<ElementEffectEntering
					motionProps={{
						initial: { y: "5rem" },
						animate: { y: 0, transition: { duration: 1 } },
					}}
				>
					<Heading
						as="p"
						textAlign="justify"
						fontSize={{ base: "1rem", md: "1.25rem" }}
						fontWeight="semibold"
						lineHeight="3rem"
					>
						Với thành công vang dội của các mùa trước,{" "}
						<Text as="span" fontWeight="bold" color="main">
							UniHack 2024
						</Text>{" "}
						đã trở lại đầy hứa hẹn với sự tổ chức của Google Developer Student
						Club - Danang University of Science and Technology và Google
						Developer Group - Cloud Danang.
					</Heading>
				</ElementEffectEntering>
			</Box>
		</Box>
	);
}
