import { Box, Image, VStack } from "@chakra-ui/react";
import Title from "components/elements/Title";
import ElementEffectEntering from "components/shared/effect/ElementEffectEntering";
import { motion } from "framer-motion";
import { FC, useMemo } from "react";
import { Gradient } from "types/common";
import { Sponsor, SponsorGroupStyling } from "types/sponsor";
import { getRGBA, getCSSLinearGradient } from "utils";

export type SponsorItemProps = {
	onClick: () => void;
	sponsor: Sponsor;
	styling: SponsorGroupStyling;
};

const SponsorItem: FC<SponsorItemProps> = ({ onClick, sponsor, styling }) => {
	return (
		<Box
			mb={{ base: "1.5rem", md: "0" }}
			as={motion.div}
			whileHover={{ scale: 1.2 }}
		>
			<VStack>
				<SponsorImage
					sponsor={sponsor}
					onClick={onClick}
					sizeWeight={styling?.sizeWeight}
					borderGradient={styling?.borderGradient}
				/>
				<SponsorTitle sponsor={sponsor} />
			</VStack>
		</Box>
	);
};

export default SponsorItem;

/**************************************/

type SponsorImageProps = {
	sponsor: Sponsor;
	onClick: () => void;
	sizeWeight: number;
	borderGradient?: Gradient;
};

const SponsorImage: FC<SponsorImageProps> = ({
	sponsor,
	onClick,
	sizeWeight = 1,
	borderGradient,
}) => {
	const BASE_RING_SIZE_PX = 4; // 4px

	const size = useMemo(() => {
		return {
			base: 100 * sizeWeight,
			md: 150 * sizeWeight,
		};
	}, [sizeWeight]);

	const glowShadow = `0 0 ${4 * sizeWeight}rem ${0.4 * sizeWeight}rem ${getRGBA(
		borderGradient?.to ?? "#7FE786",
		0.6
	)}`;

	const ringBorderGradient = getCSSLinearGradient(borderGradient);
	const hasDetails = !!sponsor.paragraphs || !!sponsor.subContent;

	return (
		<Box
			className="outter-ring"
			display="flex"
			placeContent="center"
			cursor={hasDetails ? "pointer" : "default"}
			borderRadius="100%"
			boxShadow={glowShadow}
			transition="all 0.3s"
			backgroundImage={ringBorderGradient}
			p={`${BASE_RING_SIZE_PX * sizeWeight}px`}
		>
			<Box
				className="inner-ring"
				borderRadius="100%"
				bg="transparent"
				border={`1px solid white`}
				p={`${BASE_RING_SIZE_PX * sizeWeight}px`}
			>
				<Image
					src={sponsor.logo}
					width={size}
					height={size}
					alt={sponsor.name}
					borderRadius="100%"
					loading="lazy"
					aria-label={sponsor.name}
					onClick={onClick}
				/>
			</Box>
		</Box>
	);
};

type SponsorTitleProps = {
	sponsor: Sponsor;
};

const SponsorTitle: FC<SponsorTitleProps> = ({ sponsor }) => {
	return (
		<Box mt="1.25rem">
			<ElementEffectEntering
				motionProps={{
					initial: { opacity: 0, y: 50 },
					animate: {
						opacity: 1,
						y: 0,
						transition: { duration: 0.75 },
					},
				}}
			>
				<Title
					title={sponsor.name}
					ElementType="h3"
					titleProps={{
						fontSize: { base: "1.25rem", md: "1.5rem" },
						fontWeight: "semibold",
						overflowWrap: "break-word",
						width: "12rem",
					}}
				/>
			</ElementEffectEntering>
		</Box>
	);
};
