import { Box, Heading, Image, Text } from "@chakra-ui/react";
import { plane_icon } from "assets/images";
import ElementEffectEntering from "components/shared/effect/ElementEffectEntering";
import rulesData, { RuleSection, RuleSubSection } from "types/models/rulesData";

export default function Rules() {
  const specialText1 = "**BTC không hỗ trợ ghép đội cho thí sinh đăng ký tham gia cuộc thi với hình thức cá nhân.**";
  const specialText2 =
    "**Phí cam kết sẽ không được hoàn trả trong trường hợp đội thi tự ý bỏ thi (tính từ sau khi đội thi đăng ký và thanh toán phí thành công) và không hoàn thành các điều kiện theo bảng cam kết của cuộc thi, các điều kiện liên quan đến việc tham dự các sự kiện xuyên suốt cuộc thi.**";
  return (
    <Box w="100%" color="white" maxW="1300px" mx="auto" minH="100vh">
      <Box display="flex" flexDirection="column" gap="1rem">
        <ElementEffectEntering
          motionProps={{
            initial: { y: "5rem" },
            animate: { y: 0, transition: { duration: 1 } },
          }}
        >
          <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" gap="1.25rem" w="100%" mt="4rem">
            <Box display="flex" gap="2rem" mx="auto" mb="2rem" alignItems="center">
              <Heading as="h4" fontSize={{ base: "2rem", md: "3rem" }} textAlign="left">
                THỂ LỆ CUỘC THI
              </Heading>
            </Box>
          </Box>
        </ElementEffectEntering>
        {rulesData.map((section: RuleSection, index: number) => (
          <Box key={index} display="flex" flexDirection="column" gap="1rem" textAlign="justify">
            <ElementEffectEntering
              motionProps={{
                initial: { y: "5rem" },
                animate: { y: 0, transition: { duration: 1 } },
              }}
            >
              <Box display="flex" gap="1rem" alignItems="center">
                <Image src={plane_icon} alt="icon" width="40px" height="40px" display={{ base: "none", md: "block" }} />
                <Heading color="main" textAlign="left" textTransform="uppercase">
                  {section.heading}
                </Heading>
              </Box>
            </ElementEffectEntering>
            {typeof section.content === "string" ? (
              <ElementEffectEntering
                motionProps={{
                  initial: { y: "5rem" },
                  animate: { y: 0, transition: { duration: 1 } },
                }}
              >
                <Text ml={{ base: "0px", md: "3.75rem" }} fontSize="1.25rem">
                  {section.content}
                </Text>
              </ElementEffectEntering>
            ) : (
              section.content.map((subSection: RuleSubSection, subIndex: number) => (
                <Box key={subIndex} display="flex" flexDirection="column" gap="0.5rem">
                  {subSection.subHeading && (
                    <ElementEffectEntering
                      motionProps={{
                        initial: { y: "5rem" },
                        animate: { y: 0, transition: { duration: 1 } },
                      }}
                    >
                      <Text
                        fontWeight="medium"
                        display="flex"
                        gap="1rem"
                        alignItems="center"
                        fontSize="1.35rem"
                        textAlign="left"
                        textTransform="uppercase"
                      >
                        <Image src={plane_icon} alt="icon" width="40px" height="40px" display={{ base: "none", md: "block" }} />
                        {subSection.subHeading}
                      </Text>
                    </ElementEffectEntering>
                  )}
                  {Array.isArray(subSection.text) ? (
                    <Box
                      as="ul"
                      ml={{ base: "0px", md: "3.75rem" }}
                      fontSize="1.25rem"
                      listStyleType="none"
                      display="flex"
                      flexDirection="column"
                      gap="0.75rem"
                    >
                      {subSection.text.map((item, itemIndex) => (
                        <ElementEffectEntering
                          motionProps={{
                            initial: { y: "5rem" },
                            animate: { y: 0, transition: { duration: 1 } },
                          }}
                        >
                          <Text
                            as="li"
                            key={itemIndex}
                            fontStyle={item.includes(specialText1) || item.includes(specialText2) ? "italic" : "normal"}
                            textAlign={itemIndex === 1 ? "left" : "justify"}
                          >
                            {item}
                          </Text>
                        </ElementEffectEntering>
                      ))}
                    </Box>
                  ) : (
                    <Text ml="6rem" fontSize="1.25rem">
                      {subSection.text.includes("**BTC không hỗ trợ ghép đội cho thí sinh đăng ký tham gia cuộc thi với hình thức cá nhân.**") ? (
                        <ElementEffectEntering
                          motionProps={{
                            initial: { y: "5rem" },
                            animate: { y: 0, transition: { duration: 1 } },
                          }}
                        >
                          <Text as="span" fontStyle="italic">
                            {subSection.text}
                          </Text>
                        </ElementEffectEntering>
                      ) : (
                        <ElementEffectEntering
                          motionProps={{
                            initial: { y: "5rem" },
                            animate: { y: 0, transition: { duration: 1 } },
                          }}
                        >
                          subSection.text
                        </ElementEffectEntering>
                      )}
                    </Text>
                  )}
                </Box>
              ))
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
}
