import {
	Box,
	Grid,
	GridItem,
	Heading,
	Image,
	List,
	ListItem,
	Text,
} from '@chakra-ui/react';
import {
	arrow_time_line,
	line,
	line_right,
	plane_icon,
	plane_icon_dark,
	plane_timeline,
} from 'assets/images';
import TimelineChild from 'components/elements/TimelineChild';
import Title from 'components/elements/Title';
import ElementEffectEntering from 'components/shared/effect/ElementEffectEntering';
import { FaBookOpen, FaCode, FaLightbulb, FaTrophy } from 'react-icons/fa';
import {
	VerticalTimeline,
	VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { TimeLinesData } from 'types/common';

const timeLinesData: TimeLinesData[] = [
	{
		title: 'Mở đơn đăng ký',
		status: 'Online',
		timelines: [
			'10/06 - Mở đơn đăng ký dự thi',
			'10/07 - Đóng đơn đăng ký dự thi',
		],
		timeRange: '10/06 - 10/07',
		Icon: FaBookOpen,
	},
	{
		title: 'Info Session - Khai mạc cuộc thi UniHack 2024',
		status: 'Offline',
		timelines: ['14/07 - Info Session - Khai mạc cuộc thi'],
		timeRange: '14/07',
		Icon: FaLightbulb,
	},
	{
		title: 'Vòng loại ý tưởng',
		status: 'Offline',
		timelines: ['21/07 - Pitching Day - Triển lãm ý tưởng'],
		timeRange: '21/07',
		Icon: FaTrophy,
	},
	{
		title: 'Coding Day - Vòng lập trình',
		status: 'Offline',
		timelines: ['27-28/07 - Coding Day - Vòng lập trình'],
		timeRange: '27-28/07',
		Icon: FaCode,
	},
	{
		title: 'Demo Day - Chung kết cuộc thi UniHack 2024',
		status: 'Offline',
		timelines: [
			'28/07 - Triển lãm gian hàng NTT',
			'28/07 - Demo Day - Chung kết cuộc thi',
		],
		timeRange: '28/07',
		Icon: FaCode,
	},
];

// need refactor render in MO

export interface TimelineSectionProps {}

export default function TimelineSection(props: TimelineSectionProps) {
	return (
		<Box
			id='timeline'
			minH='100vh'
			display='flex'
			flexDirection='column'
			gap='5rem'
			alignItems='center'
		>
			<Title title='LỊCH TRÌNH CUỘC THI' />
			<Box display={{ base: 'block', lg: 'none' }} w='100%'>
				<VerticalTimeline>
					{timeLinesData.map((timeline, index) => (
						<VerticalTimelineElement
							key={index}
							className='vertical-timeline-element--work'
							contentStyle={{
								background: `${
									index % 2 !== 0 ? '#fff' : '#29C0CE'
								}`,
							}}
							contentArrowStyle={{
								borderRight: `7px solid ${
									index % 2 !== 0 ? '#fff' : '#29C0CE'
								}`,
							}}
							date={timeline.timeRange}
							dateClassName={`${
								index % 2 !== 0 ? 'text-black' : 'text-white'
							}`}
							iconStyle={{ background: '#29C0CE', color: '#fff' }}
							icon={<timeline.Icon />}
						>
							<Heading
								as='h3'
								fontSize='1.5rem'
								color={`${index % 2 !== 0 ? '#000' : '#fff'}`}
								fontWeight='semibold'
								className='vertical-timeline-element-title'
								textTransform='uppercase'
							>
								{timeline.title}
							</Heading>
							<Heading
								as='h4'
								fontSize='1.125rem'
								mt='0.5rem'
								fontWeight='normal'
								className='vertical-timeline-element-subtitle'
								color={`${index % 2 !== 0 ? '#000' : '#fff'}`}
							>
								{timeline.status}
							</Heading>
							<List mt='0.75rem'>
								{timeline.timelines.map((item, subIndex) => (
									<ListItem
										display='flex'
										alignItems='flex-start'
										gap='1rem'
										key={subIndex}
									>
										<Image
											src={
												index % 2 !== 0
													? plane_icon_dark
													: plane_icon
											}
											w={8}
											h={7}
										/>
										<Text
											as='span'
											color={`${
												index % 2 !== 0
													? '#000'
													: '#fff'
											}`}
										>
											{item}
										</Text>
									</ListItem>
								))}
							</List>
						</VerticalTimelineElement>
					))}
				</VerticalTimeline>
			</Box>
			<Grid
				gridTemplateColumns='15% 35% 35% 15%'
				placeItems='center'
				rowGap='8rem'
				position='relative'
				width='100%'
				display={{ base: 'none', lg: 'grid' }}
			>
				<ElementEffectEntering
					motionProps={{
						initial: { opacity: 0 },
						animate: {
							opacity: 1,
							transition: { duration: 0.5, delay: 0.4 },
						},
					}}
					containerProps={{
						position: 'absolute',
						right: '0',
						height: '100%',
					}}
					elementProps={{
						position: 'absolute',
						top: '50%',
						transform: 'translateY(-50%)',
						height: '58%',
						width: '4px',
					}}
				>
					<Image src={line_right} w='4px' h='100%' />
				</ElementEffectEntering>
				<ElementEffectEntering
					motionProps={{
						initial: { opacity: 0 },
						animate: {
							opacity: 1,
							transition: { duration: 0.5, delay: 0.4 },
						},
					}}
					containerProps={{
						position: 'absolute',
						right: '-8px',
						top: '52%',
						transform: 'translateY(-50%) rotate(-90deg)',
					}}
				>
					<Image src={arrow_time_line} />
				</ElementEffectEntering>
				<GridItem w='100%' textAlign='center' color='white'>
					<ElementEffectEntering
						motionProps={{
							initial: { x: -100 },
							animate: { x: 0, transition: { duration: 0.5 } },
						}}
					>
						<Box display='flex' alignItems='center'>
							<Image src={plane_timeline} />
							<Image src={line} flex='1' />
						</Box>
					</ElementEffectEntering>
				</GridItem>
				<GridItem w='100%'>
					<ElementEffectEntering
						motionProps={{
							initial: { x: -100 },
							animate: {
								x: 0,
								transition: { duration: 0.5, delay: 0.2 },
							},
						}}
					>
						<TimelineChild date='10/06' title='CHÍNH THỨC MỞ ĐƠN' />
					</ElementEffectEntering>
				</GridItem>
				<GridItem w='100%'>
					<ElementEffectEntering
						motionProps={{
							initial: { x: -100 },
							animate: {
								x: 0,
								transition: { duration: 0.5, delay: 0.4 },
							},
						}}
					>
						<TimelineChild date='10/07' title='ĐÓNG ĐƠN ĐĂNG KÝ' />
					</ElementEffectEntering>
				</GridItem>
				<GridItem w='100%' overflow='hidden'>
					<ElementEffectEntering
						motionProps={{
							initial: { x: -100 },
							animate: {
								x: 0,
								transition: { duration: 0.5, delay: 0.6 },
							},
						}}
					>
						<TimelineChild
							date='14/07'
							title='INFO SESSION'
							desc='KHAI MẠC CUỘC THI'
							showArrow={false}
							lineProps={{
								width: '150%',
								left: '45%',
							}}
						/>
					</ElementEffectEntering>
				</GridItem>
				<GridItem w='100%' textAlign='center' color='white'>
					<ElementEffectEntering
						motionProps={{
							initial: { x: 100 },
							animate: {
								x: 0,
								transition: { duration: 0.5, delay: 0.6 },
							},
						}}
					>
						<Box display='flex' alignItems='center'>
							<Image
								src={plane_timeline}
								transform='scaleX(-1)'
							/>
							<Image
								src={line}
								flex='1'
								transform='rotate(180deg)'
							/>
						</Box>
					</ElementEffectEntering>
				</GridItem>
				<GridItem w='100%'>
					<ElementEffectEntering
						motionProps={{
							initial: { x: 100 },
							animate: {
								x: 0,
								transition: { duration: 0.5, delay: 0.4 },
							},
						}}
					>
						<TimelineChild
							date='28/07'
							title='DEMO DAY'
							desc='CHUNG KẾT CUỘC THI'
							reverse={true}
						/>
					</ElementEffectEntering>
				</GridItem>
				<GridItem w='100%'>
					<ElementEffectEntering
						motionProps={{
							initial: { x: 100 },
							animate: {
								x: 0,
								transition: { duration: 0.5, delay: 0.2 },
							},
						}}
					>
						<TimelineChild
							date='27 - 28/07'
							title='CODING DAY'
							desc='VÒNG LẬP TRÌNH'
							reverse={true}
						/>
					</ElementEffectEntering>
				</GridItem>
				<GridItem w='100%' overflow='hidden'>
					<ElementEffectEntering
						motionProps={{
							initial: { x: 100 },
							animate: { x: 0, transition: { duration: 0.5 } },
						}}
					>
						<TimelineChild
							date='21/07'
							title='PITCHING DAY'
							desc='TRIỂN LÃM Ý TƯỞNG'
							reverse={true}
							showArrow={false}
							lineProps={{
								width: '150%',
								left: '48%',
							}}
						/>
					</ElementEffectEntering>
				</GridItem>
			</Grid>
		</Box>
	);
}
