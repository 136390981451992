import { useState, useEffect } from 'react';
import { useTheme } from '@chakra-ui/react';

const emToPixels = (em: string) => parseFloat(em) * 16;

const useBreakpoint = () => {
  const theme = useTheme();
  const breakpoints = theme.breakpoints;

  const getCurrentBreakpoint = (width: number) => {
    if (width >= emToPixels(breakpoints.xl)) return 'xl';
    if (width >= emToPixels(breakpoints.lg)) return 'lg';
    if (width >= emToPixels(breakpoints.md)) return 'md';
    if (width >= emToPixels(breakpoints.sm)) return 'sm';
    return 'base';
  };

  const [currentBreakpoint, setCurrentBreakpoint] = useState(() => getCurrentBreakpoint(window.innerWidth));

  useEffect(() => {
    const handleResize = () => {
      setCurrentBreakpoint(getCurrentBreakpoint(window.innerWidth));
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [breakpoints]);

  return currentBreakpoint;
};

export default useBreakpoint;
