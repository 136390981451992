import { getRGBA } from "utils";
import { SponsorGroupStyling } from "../types/sponsor";
import { SponsorGroup } from "types/sponsor";
import { Link, List, ListItem, UnorderedList } from "@chakra-ui/react";

export const SPONSOR_STYLES: Record<string, SponsorGroupStyling> = {
	SILVER: {
		sizeWeight: 1.5,
		borderGradient: {
			direction: 90,
			from: getRGBA("#656C73", 0.8),
			fromPercent: 0,
			to: getRGBA("#BFCCD9", 0.8),
			toPercent: 100,
		},
	},
	BRONZE: {
		sizeWeight: 1.25,
		borderGradient: {
			direction: 146.91,
			from: getRGBA("#0734CC", 0.8),
			fromPercent: 13.35,
			to: getRGBA("#E87B07", 0.8),
			toPercent: 89.41,
		},
	},
};

export const SPONSORS: SponsorGroup[] = [
	{
		title: "Nhà tài trợ Bạc",
		styling: SPONSOR_STYLES.SILVER,
		sponsors: [
			{
				logo: "/sponsors/kms-technology.png",
				name: "KMS Technology",
				title: "KMS Technology",
				paragraphs: [
					"✨ Thành lập năm 2009, KMS Technology là công ty dịch vụ tư vấn và phát triển phần mềm toàn diện có trụ sở chính tại Hoa Kỳ, với các trung tâm phát triển tại Việt Nam và Mexico.",
					"Với hơn 15 năm kinh nghiệm trong ngành, KMS Technology được tín nhiệm trên toàn cầu về chất lượng vượt trội của dịch vụ, giải pháp và trình độ chuyên môn cao của đội ngũ kỹ sư.",
					"✨ Công ty được trao tặng nhiều giải thưởng chuyên môn trong lĩnh vực công nghệ do khách hàng và cộng đồng bình chọn, được vinh danh trong danh sách những nơi làm việc tốt nhất tại Việt Nam, Châu Á và Hoa Kỳ trong nhiều năm liên tiếp.",
					"KMS Technology tiếp tục giữ vững tầm nhìn là công ty công nghệ hàng đầu, cung cấp những giải pháp phần mềm tối ưu cho khách hàng dựa trên bề dày kinh nghiệm chuyên ngành, giải pháp kỹ thuật tân tiến và chất lượng dịch vụ xuất sắc, phát huy tối đa tiềm năng của từng cá nhân; hướng đến thành công chung cho cả khách hàng, cộng đồng và nhân viên.",
				],
				subContent: null,
			},
		],
	},
	{
		title: "Nhà tài trợ Đồng",
		styling: SPONSOR_STYLES.BRONZE,
		sponsors: [
			{
				logo: "/sponsors/paradoxai.png",
				name: "Paradox",
				title: "Paradox",
				paragraphs: [
					"Phát triển ứng dụng trí tuệ nhân tạo (AI) trong lĩnh vực nhân sự, 𝗣𝗮𝗿𝗮𝗱𝗼𝘅 được thành lập vào năm 2016 tại Mỹ với sứ mệnh: tận dụng sức mạnh công nghệ để nâng cao trải nghiệm của con người.",
					"Sản phẩm Trợ lý tuyển dụng Olivia của Paradox có thể giúp đảm nhiệm và sắp xếp hợp lý công việc của các nhóm tuyển dụng như sàng lọc ứng viên, hỗ trợ trả lời câu hỏi của ứng viên 24/7, sắp xếp lịch phỏng vấn, và nhiều hơn thế nữa thông qua việc tương tác với ứng viên một cách nhanh chóng, dễ dàng trên nhiều nền tảng.",
					"✨ Paradox đã và đang hỗ trợ giải pháp cho nhiều tập đoàn hàng đầu thế giới như Unilever, McDonald's, FedEx Ground, CVS Health, Lowe's, General Motors, Amazon, Nestlé, v.v.",
					"Nhờ những nỗ lực không ngừng, Paradox đã gặt hái được nhiều giải thưởng danh giá: • 3 năm chiến thắng giải thưởng HR Executive Top Product of The Year. • Lọt vào danh sách những công ty khởi nghiệp đáng làm việc nhất trong 3 năm 2020, 2021 và 2022 do Forbes bình chọn. • 3 năm liên tiếp trong danh sách 5000 công ty tư nhân phát triển nhanh nhất nước Mỹ.",
					"📍 Hiện tại, với hơn 600 nhân sự trên toàn cầu, trong đó có gần 300 nhân sự làm việc tại Việt Nam (Đà Nẵng và TP. Hồ Chí Minh), Paradox đang tiếp tục mở rộng một đội ngũ nhân sự tài năng để cùng nhau tạo ra những bước tiến mới trong lĩnh vực nhân sự và tuyển dụng và nóng lòng chào đón thêm các đồng đội ở nhiều vị trí.",
					<>
						Để biết thêm chi tiết về các vị trí tuyển dụng, xem tại:{" "}
						<Link
							href="https://vncareers.paradox.ai/"
							target="_blank"
							rel="noreferrer"
							color="blue.500"
						>
							https://vncareers.paradox.ai/
						</Link>
					</>,
				],
				subContent: null,
				// subContent: {
				// 	img: "/sponsors/paradoxai.png",
				// 	career: {
				// 		title: "𝗣𝗮𝗿𝗮𝗱𝗼𝘅 𝗩𝗶𝗲𝘁𝗻𝗮𝗺",
				// 		list: [
				// 			<>
				// 				Trụ sở:
				// 				<List ml={6}>
				// 					<ListItem color="black">
				// 						• TP Đà Nẵng : Tầng 2 - 3, 460 Nguyễn Hữu Thọ, TP.Đà Nẵng
				// 					</ListItem>
				// 					<ListItem>
				// 						• TP Hồ Chí Minh: Tầng 6, 60 Nguyễn Đình Chiểu, Quận 1,
				// 						TP.Hồ Chí Minh
				// 					</ListItem>
				// 				</List>
				// 			</>,
				// 			"Phone: (+84) 236 3788099",
				// 			<>
				// 				Skype: <code>live:hr_17855</code>
				// 			</>,
				// 			<>
				// 				Linkedin:{" "}
				// 				<Link
				// 					color="blue.500"
				// 					href="https://www.linkedin.com/company/paradoxolivia"
				// 					target="_blank"
				// 					rel="noreferrer"
				// 				>
				// 					https://www.linkedin.com/company/paradoxolivia
				// 				</Link>
				// 			</>,
				// 			<>
				// 				Fanpage:{" "}
				// 				<Link
				// 					href="https://www.facebook.com/ParadoxVietnam"
				// 					target="_blank"
				// 					rel="noreferrer"
				// 					color="blue.500"
				// 				>
				// 					https://www.facebook.com/ParadoxVietnam
				// 				</Link>
				// 			</>,
				// 		],
				// 	},
				// },
			},
		],
	},
	{
		title: "Đơn vị hỗ trợ",
		sponsors: [
			{
				logo: "/sponsors/vtc-academy.png",
				name: "VTC Academy",
				title: "VTC Academy Đà Nẵng",
				paragraphs: [
					"🌟Học viện Công nghệ thông tin và Thiết kế VTC (VTC Academy) được thành lập năm 2010, hiện đã trải qua hơn một thập kỷ xây dựng và phát triển.",
					"VTC Academy có 4 chi nhánh trải dài từ Bắc vào Nam và chi nhánh ở Đà Nẵng hiện đã được hơn 2 năm hoạt động.",
					"🎯 Với mục tiêu trở thành học viện hàng đầu châu Á về đào tạo Công nghệ thông tin và Thiết kế trong thập kỷ tới, VTC Academy luôn không ngừng nâng cao chất lượng đào tạo thông qua việc nắm bắt kịp thời xu thế phát triển của công nghệ, cập nhật liên tục chương trình học theo xu hướng ngành nghề của thế giới cũng như nhu cầu thực tiễn của các doanh nghiệp dưới sự cố vấn của các giảng viên danh tiếng từ những trường đại học hàng đầu thế giới như Đại học Harvard, Đại học Stanford, Đại học Carnegie Mellon,…",
					<>
						Tỷ lệ sinh viên của VTC Academy có việc làm sau khi tốt nghiệp hiện
						đạt 100%.
					</>,
				],
				subContent: null,
			},
			{
				logo: "/sponsors/arena-multimedia-fpt.png",
				name: "FPT Arena Multimedia",
				title: "FPT Arena Multimedia",
				paragraphs: [
					"🌟 𝐅𝐏𝐓 𝐀𝐫𝐞𝐧𝐚 𝐌𝐮𝐥𝐭𝐢𝐦𝐞𝐝𝐢𝐚 – gọi tắt là FAN: Hệ thống đào tạo Mỹ thuật đa phương tiện, ra đời từ sự hợp tác của Tập đoàn FPT và Tập đoàn CNTT toàn cầu Aptech Ấn Độ. Thành lập từ tháng 7 – 2004, FPT Arena Multimedia là hệ thống đào tạo Mỹ thuật đa phương tiện đầu tiên và tiên phong đặt khái niệm Multimedia tại Việt Nam.",
					"📚 FPT Arena đang là nguồn cung cấp nhân lực chủ đạo cho ngành Multimedia trong nước với hơn 10 khóa học khác nhau từ: Thiết kế hình ảnh, thiết kế Website, biên tập Video, thiết kế game, hoạt hình 3D, kỹ xảo điện ảnh…",
					"🎇 Kế thừa văn hoá Tổ chức giáo dục FPT cùng quy chuẩn đào tạo của Tập đoàn CNTT Aptech, FPT Arena duy trì chất lượng giáo dục thực tiễn chất lượng cao, quy tụ giảng viên dày kinh nghiệm thực chiến, hợp tác liên thông với các trường Đại học lớn trên thế giới.",
					"Hiện nay FPT Arena đang hoạt động trên toàn quốc gồm 4 cơ sở chính: Hà Nội, Hồ Chí Minh, Đà Nẵng, Cần Thơ.",
					// 					📍 Địa chỉ cơ sở Đà Nẵng: 130 Đống Đa, P.Thuận Phước, Q.Hải Châu, TP. Đà Nẵng
					"📍 Địa chỉ cơ sở Đà Nẵng: 130 Đống Đa, P.Thuận Phước, Q.Hải Châu, TP. Đà Nẵng",
					<>
						📍 Fanpage:{" "}
						<Link
							href="https://www.facebook.com/FptArenaMultimediaDaNang"
							target="_blank"
							rel="noreferrer"
							color="blue.500"
						>
							https://www.facebook.com/FptArenaMultimediaDaNang
						</Link>
					</>,
					<>
						📍 Website:{" "}
						<Link
							href="http://arena.fpt.edu.vn/"
							target="_blank"
							rel="noreferrer"
							color="blue.500"
						>
							http://arena.fpt.edu.vn/
						</Link>
					</>,
				],
				subContent: {
					img: "/sponsors/arena-multimedia-fpt.png",
					enrollment: {
						title: "Tuyển sinh ngành Multimedia tại FPT Arena",
						list: [
							"Gia nhập ngành Multimedia với lộ trình học tập rõ ràng, nhận chứng chỉ quốc tế cực xịn có hiệu lực ở 20 quốc gia, đi xin việc ở đâu cũng dễ?",
							<>
								Nhằm đáp ứng nhu cầu của mọi học viên, FPT Arena mở rộng chương
								trình đào tạo với đa dạng các khóa học:
								<List>
									<ListItem>
										- Khóa dài hạn (02 năm - 04 kỳ): Graphic Design, Digital
										Design, Film Making & Game Design, 3D Animation
									</ListItem>
									<ListItem>- Khóa Graphic Design</ListItem>
									<ListItem>- Khóa Graphic Design & Web Design</ListItem>
									<ListItem>- Khóa Art & Web Design</ListItem>
									<ListItem>- Khóa Film Making & Game Design</ListItem>
									<ListItem>- Khóa 3D Modeling & Animation</ListItem>
									<ListItem>
										- Khóa Diploma in Film Making & Game Design
									</ListItem>
								</List>
							</>,
							"Nhận tư vấn chi tiết về chương trình học tại đây: https://arena.fpt.edu.vn/dang-ky/",
							"ĐẶC BIỆT, khi đăng ký nhập học, bạn sẽ có cơ hội làm bài test năng lực sáng tạo và nhận học bổng lên tới 10 triệu đồng!",
						],
					},
				},
			},
		],
	},
	{
		title: "Nhà tài trợ Hiện Vật",
		sponsors: [
			{
				logo: "/sponsors/phongvu.png",
				name: "Phong Vũ",
				title: "Công ty Cổ phần Thương mại Dịch vụ Phong vũ",
				paragraphs: [
					"Công ty Cổ phần Thương mại - Dịch vụ Phong Vũ, đơn vị tiên phong trong lĩnh vực bán lẻ thiết bị điện tử tại Việt Nam.",
					"⚡️ Với kinh nghiệm nhiều năm hoạt động và lòng tin của khách hàng, Phong Vũ không chỉ đơn thuần là một nhà bán lẻ uy tín mà còn là nhà đồng hành của các bạn trẻ đam mê công nghệ. Đặc biệt, Phong Vũ đã và đang đưa vào thị trường các sản phẩm laptop và PC tích hợp trí tuệ nhân tạo, phục vụ cho nhu cầu lập trình và train model AI.",
					"🌟 Lần hợp tác này cùng Phong Vũ, UniHack 2024 mong muốn góp phần thúc đẩy sự phát triển của trí tuệ nhân tạo tại Việt Nam cũng như tạo ra cơ hội học tập và trải nghiệm mới cho các bạn trẻ yêu thích công nghệ. Đồng thời, nhân dịp kỷ niệm sinh nhật 27 tuổi, Phong Vũ cũng mang đến nhiều chương trình khuyến mãi hấp dẫn, với ưu đãi lên đến 2.700.000 VNĐ.",
					<br />,
					"𝐏𝐡𝐨𝐧𝐠 𝐕𝐮̃",
					<List>
						<ListItem>
							📥Trụ sở: 14-16-18 Nguyễn Văn Linh, Hải Châu, Đà Nẵng
						</ListItem>
						<ListItem>📞 Hotline: 0236 7306867 </ListItem>
						<ListItem>
							🌐 Website:{" "}
							<Link href="https://phongvu.vn/">https://phongvu.vn/</Link>
						</ListItem>
					</List>,
				],
				subContent: null,
			},
		],
	},
	{
		title: "Nhà tài trợ Học Bổng",
		sponsors: [
			{
				logo: "/sponsors/enetenglish.png",
				name: "ENET ENGLISH",
				title: "Trung tâm Anh ngữ ENET ",
				paragraphs: [
					"✨ Bắt đầu từ năm 2017, với tâm huyết mang đến cho học viên những trải nghiệm học tiếng Anh hiệu quả và thú vị nhất, 𝐄𝐍𝐄𝐓 đã chính thức đặt nền móng đầu tiên tại ĐÀ NẴNG. Kết hợp phương pháp giảng dạy sáng tạo, lộ trình học tinh gọn cùng đội ngũ giáo viên tâm huyết và giàu kinh nghiệm, 𝐄𝐍𝐄𝐓 nhanh chóng khẳng định vị thế của mình trong lĩnh vực giáo dục tiếng Anh.",
					"✨ 𝐄𝐍𝐄𝐓 đã thành công trong việc đào tạo cho hàng chục nghìn học viên với các chương trình Tiếng Anh đa dạng:",
					<>
						<List ml={6}>
							<ListItem>▪️ Khóa học Nền Tảng - Cải thiện 4 kỹ năng</ListItem>
							<ListItem>
								▪️ Tự tin nghe nói tiếng Anh cùng khóa Giao Tiếp Quốc Tế{" "}
							</ListItem>
							<ListItem>▪️ Khóa học TOEIC 4 kỹ năng</ListItem>
							<ListItem>▪️ Chương trình luyện thi IELTS</ListItem>
						</List>
					</>,
					"✨ Đến với chương trình lần này, 𝐄𝐍𝐄𝐓 hân hạnh mang đến những suất học bổng dành riêng cho các bạn sinh viên - những trái tim trẻ tràn đầy nhiệt huyết. Đây là cơ hội tuyệt vời để bạn chinh phục tiếng Anh; Bởi lẽ, đầu tư vào tiếng Anh không chỉ là khoản đầu tư không rủi ro mà còn là chìa khóa mở ra cánh cửa tương lai rạng rỡ. Hãy để 𝐄𝐍𝐄𝐓 để chắp cánh cho ước mơ tiếng Anh của bạn bay cao, bay xa hơn nhé",
					<br />,
					"🔰 𝐓𝐫𝐮𝐧𝐠 𝐭𝐚̂𝐦 𝐀𝐧𝐡 𝐍𝐠𝐮̛̃ 𝐄𝐍𝐄𝐓",
					<List>
						<ListItem>
							🏢 𝐂𝐒𝟏: 87 Chương Dương, Ngũ Hành Sơn (Gần trường ĐH Kinh Tế)
						</ListItem>
						<ListItem>
							🏢 𝐂𝐒𝟐: 48/27 Ngô Sĩ Liên, Hòa Khánh (Gần trường ĐH Bách Khoa)
						</ListItem>
						<ListItem>📞 𝐇𝐨𝐭𝐥𝐢𝐧𝐞: 0931 998 004</ListItem>
					</List>,
				],
				subContent: null,
			},
			{
				logo: "/sponsors/elsa.png",
				name: "ELSA Speak",
				title: "Công ty TNHH ELSA",
				paragraphs: [
					"✨ ELSA (English Language Speech Assistant) là startup về công nghệ giáo dục tại Silicon Valley đầy triển vọng & đang tăng trưởng rất nhanh. Được thành lập vào năm 2015 bởi Văn Đinh Hồng Vũ (CEO), cùng với người bạn Bồ Đào Nha là tiến sĩ Xavier Anguera (giám đốc công nghệ – CTO), ELSA có công nghệ Trí Tuệ Nhân Tạo với khả năng học sâu (deep learning) và nhận diện giọng nói (speech recognition) độc quyền.",
					"✨ Ứng dụng ELSA Speak là ứng dụng học nói và giao tiếp tiếng Anh gần như duy nhất có thể sửa lỗi phát âm chính xác từng âm tiết, đồng thời đưa ra nhận xét tức thì và hướng dẫn sửa lại chuẩn xác. Năm 2023, ELSA đã đạt 50 triệu lượt tải tại 195+ quốc gia trên thế giới.",
				],
				subContent: null,
			},
		],
	},
	{
		title: "Nhà tài trợ Địa điểm",
		sponsors: [
			{
				logo: "/sponsors/nhuminh.png",
				name: "Như Minh Plaza Đà Nẵng",
				title: "Như Minh Plaza Đà Nẵng",
				paragraphs: [
					"Tọa lạc trên cung đường Phạm Văn Đồng nối dài giữa bãi biển Mỹ Khê xinh đẹp với cầu sông Hàn dẫn đến trung tâm thành phố Đà Nẵng, khách sạn Nhu Minh Plaza Đà Nẵng chính là điểm nghỉ dưỡng thuận tiện cho bạn khám phá mọi khía cạnh vẻ đẹp của thành phố từ hoang sơ thanh bình đến hiện đại sôi động.",
					"Khách sạn Nhu Minh Plaza Đà Nẵng sở hữu 90 phòng nghỉ, đa dạng hạng phòng, thích hợp nhóm khách lưu trú từ 2 đến 4 khách mỗi phòng mà vẫn đảm bảo không gian nghỉ ngơi riêng tư và thoải mái. Ngoài ra, ưu thế diện tích phòng nghỉ rộng rãi từ 30m2 - 65m2, tất cả các hạng phòng đều được thiết kế với tầm nhìn hướng biển hoặc thành phố từ cửa sổ lớn sẽ giúp trải nghiệm nghỉ dưỡng của bạn càng khác biệt và trọn vẹn hơn nữa.",
					<>
						Tiện ích:
						<UnorderedList styleType="'- '">
							<ListItem>
								Buffet sáng hảo hạng tại nhà hàng Canary tại tầng 2 của khách
								sạn
							</ListItem>
							<ListItem>
								Hồ bơi nguyên tầng trong nhà tại tầng 7 của khách sạn
							</ListItem>
							<ListItem>
								Trung tâm thể hình và khu vực vui chơi của trẻ em tại tầng 8 của
								khách sạn
							</ListItem>
						</UnorderedList>
					</>,
					<>
						Liên hệ:
						<UnorderedList styleType="'- '">
							<ListItem>Hotline: 02363 555 666</ListItem>
							<ListItem>Email: info@nhuminhplazahotel.com</ListItem>
							<ListItem>
								Website:{" "}
								<Link href="https://nhuminhplazahotel.com/">
									https://nhuminhplazahotel.com/
								</Link>
							</ListItem>
						</UnorderedList>
					</>,
				],
				subContent: {
					img: "/sponsors/nhuminh.png",
					career: {
						title: "Như Minh Plaza Đà Nẵng",
						list: [
							"Khách sạn thường xuyên tuyển dụng các vị trí thực tập sinh cho các bộ phận như lễ tân, nhà hàng, buồng phòng… nhằm tạo điều kiện cho các bạn sinh viên có cơ hội học hỏi, trải nghiệm môi trường khách sạn chuẩn 4 sao.",
							"Ứng viên nộp CV tại: hr1@nhuminhplazahotel.com",
							"Liên hệ: 0905693493 (Ms.Linh – HR)",
							"Địa chỉ: Lô 2-A2-KH Phạm Văn Đồng, An Hải Bắc, Sơn Trà, Đà Nẵng",
						],
					},
				},
			},
		],
	},
	{
		title: "Bảo trợ truyền thông",
		sponsors: [
			{
				logo: "/sponsors/ivolunteer.png",
				name: "iVolunteer",
				title: "iVolunteer",
				paragraphs: null,
				subContent: null,
			},
			{
				logo: "/sponsors/ybox.png",
				name: "YBOX",
				title: "YBox",
				paragraphs: null,
				subContent: null,
			},
		],
	},
];
