import {
	Box,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button,
	Image,
	List,
	ListItem,
	ListIcon,
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
	Hide,
	VStack,
} from "@chakra-ui/react";
import { Sponsor } from "types/sponsor";
import Title from "components/elements/Title";
import { FaCheckCircle } from "react-icons/fa";

interface SponsorCardProps {
	sponsor: Sponsor;
	isOpen: boolean;
	onClose: () => void;
}

const SponsorCard: React.FC<SponsorCardProps> = ({
	sponsor,
	isOpen,
	onClose,
}) => {
	return (
		<Modal isOpen={isOpen} onClose={onClose} size="full">
			<ModalOverlay />
			<ModalContent
				containerProps={{
					left: "50%",
					top: "50%",
					transform: "translate(-50%, -50%)",
					h: "auto",
				}}
				maxW="960px"
				minH="auto"
				mx={{ base: "2rem", md: "10vw" }}
				my={{ base: "10vh", md: "20vh" }}
				flexDirection="column"
				maxH="80vh"
			>
				<ModalHeader>{sponsor.name}</ModalHeader>
				<ModalCloseButton />
				<ModalBody flex="1" p={4} overflowY="auto">
					<Tabs variant="enclosed" height="100%">
						<TabList mb="1em">
							{sponsor.paragraphs && <Tab key="information">Thông tin</Tab>}
							{sponsor.subContent?.career && <Tab key="career">Tuyển dụng</Tab>}
							{sponsor.subContent?.enrollment && (
								<Tab key="enrollment">Tuyển sinh</Tab>
							)}
						</TabList>
						<TabPanels>
							{sponsor.paragraphs && (
								<TabPanel className="panel-content" p={{ base: 1, md: 2 }}>
									<VStack
										alignItems="center"
										mb="1em"
										p={{ base: 0, md: 2 }}
										minHeight="400px"
										h="100%"
									>
										<Image
											src={sponsor.logo}
											width="100%"
											maxWidth="200px"
											height="150px"
											objectFit="cover"
											alt={`${sponsor.name} logo`}
											border="0.1rem solid #ccc"
											loading="lazy"
											aria-label={`${sponsor.name} logo`}
										/>
										<Title
											titleProps={{
												fontSize: "1.2rem",
												fontWeight: "bold",
												mb: "1em",
												textAlign: "center",
												color: "black",
											}}
											title={sponsor.title}
										/>
										{sponsor.paragraphs?.length > 0 && (
											<Box
												fontSize={{ base: "0.9rem", md: "1rem" }}
												color="gray.700"
												textAlign="justify"
												overflowY="auto"
												lineHeight="1.6"
											>
												{sponsor.paragraphs?.map((paragraph, index) => (
													<Box key={index} mb="1em">
														{paragraph}
													</Box>
												))}
											</Box>
										)}
									</VStack>
								</TabPanel>
							)}
							{sponsor.subContent?.career && (
								<TabPanel className="panel-sub-content">
									<VStack
										alignItems="center"
										mb="1em"
										p={{ base: 0, md: 2 }}
										minHeight="400px"
										h="100%"
									>
										<Image
											src={sponsor.subContent?.img}
											width="100%"
											maxWidth="200px"
											height="150px"
											objectFit="cover"
											alt={`${sponsor.name} subContent image`}
											border="0.1rem solid #ccc"
											loading="lazy"
											aria-label={`${sponsor.name} subContent image`}
										/>
										<Box
											fontSize="1.5rem"
											fontWeight="bold"
											mb="1em"
											textAlign="center"
										>
											{sponsor.subContent?.career.title}
										</Box>
										<List
											spacing={3}
											width="100%"
											height="100%"
											overflowY="auto"
											fontSize={{ base: "0.9rem", md: "1rem" }}
										>
											{sponsor.subContent?.career?.list.map(
												(position, index) => (
													<ListItem key={index}>
														<ListIcon as={FaCheckCircle} color="green.500" />
														{position}
													</ListItem>
												)
											)}
										</List>
									</VStack>
								</TabPanel>
							)}

							{sponsor.subContent?.enrollment && (
								<TabPanel className="panel-sub-content">
									<VStack
										alignItems="center"
										mb="1em"
										p={{ base: 0, md: 2 }}
										minHeight="400px"
										h="100%"
									>
										<Image
											src={sponsor.subContent?.img}
											width="100%"
											maxWidth="200px"
											height="150px"
											objectFit="cover"
											alt={`${sponsor.name} subContent image`}
											border="0.1rem solid #ccc"
											loading="lazy"
											aria-label={`${sponsor.name} subContent image`}
										/>
										<Box
											fontSize="1.5rem"
											fontWeight="bold"
											mb="1em"
											textAlign="center"
										>
											{sponsor.subContent.enrollment.title}
										</Box>
										<List
											spacing={3}
											width="100%"
											height="100%"
											overflowY="auto"
											fontSize={{ base: "0.9rem", md: "1rem" }}
										>
											{sponsor.subContent.enrollment?.list?.map(
												(position, index) => (
													<ListItem key={index}>
														<ListIcon as={FaCheckCircle} color="green.500" />
														{position}
													</ListItem>
												)
											)}
										</List>
									</VStack>
								</TabPanel>
							)}
						</TabPanels>
					</Tabs>
				</ModalBody>
				<Hide below="md">
					<ModalFooter>
						<Button colorScheme="blue" mr={3} onClick={onClose}>
							Close
						</Button>
					</ModalFooter>
				</Hide>
			</ModalContent>
		</Modal>
	);
};

export default SponsorCard;
