import { Box, Flex, useDisclosure, VStack } from "@chakra-ui/react";
import Title from "components/elements/Title";
import SponsorCard from "components/elements/SponsorCard";
import { SPONSORS } from "data/sponsor";
import { Sponsor } from "types/sponsor";
import { useState } from "react";
import SponsorItem from "./SponsorItem";

export interface OrganizationsSectionProps {}

export default function SponsorsSection(props: OrganizationsSectionProps) {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [selectedSponsor, setSelectedSponsor] = useState<Sponsor | null>(null);

	const showDetail = (sponsor: Sponsor) => {
		const hasDetails = !!sponsor.paragraphs || !!sponsor.subContent;
		if (!hasDetails) return;

		setSelectedSponsor(sponsor);
		onOpen();
	};

	return (
		<VStack alignItems="center">
			<Title title="Nhà tài trợ" />
			<Box>
				{SPONSORS.map((sponsorGroup, index) => (
					<Box key={index} pt="2rem" pb="2rem" textAlign="center">
						<Box>
							<Title
								titleProps={{ fontSize: { base: "1.5rem", md: "2rem" } }}
								title={sponsorGroup.title}
							/>
						</Box>
						<Flex
							gap={{ base: "1rem", md: "4rem" }}
							justifyContent="center"
							mt="2rem"
							flexDirection={{ base: "column", md: "row" }}
							alignItems={{ base: "center", md: "flex-start" }}
						>
							{sponsorGroup.sponsors.map((sponsor, idx) => (
								<SponsorItem
									key={idx}
									onClick={() => showDetail(sponsor)}
									sponsor={sponsor}
									styling={sponsorGroup.styling}
								/>
							))}
						</Flex>
					</Box>
				))}
			</Box>
			{selectedSponsor && (
				<SponsorCard
					sponsor={selectedSponsor}
					isOpen={isOpen}
					onClose={onClose}
				/>
			)}
		</VStack>
	);
}
